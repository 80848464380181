import Script from 'next/script';
import { useCookies } from 'shared/contexts/CookiesContext';
import { GA_TRACKING_ID } from '../../utils/gtag';

const dev = process.env.NODE_ENV === 'development';

export default function GoogleTagManagerTrackingCode() {
  const { enabledCookies } = useCookies();
  if (!enabledCookies.tracking || dev) return null;
  return (
    <Script
      id="gtag-tracking"
      dangerouslySetInnerHTML={{
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${GA_TRACKING_ID}');
            gtag('config', 'UA-28410282-1');
            gtag('config', 'G-81644KKL5E');
            gtag('config', 'G-SMW88C72JE');
          `,
      }}
    />
  );
}

import { getCookieConsentValue } from 'react-cookie-consent';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (
    window &&
    typeof window !== 'undefined' &&
    !!window.gtag &&
    getCookieConsentValue() === 'true'
  ) {
    window.gtag('config', GA_TRACKING_ID, {
      page_location: url,
    });
    window.gtag('config', 'UA-28410282-1', {
      page_location: url,
    });
    window.gtag('config', 'G-81644KKL5E', {
      page_location: url,
    });
    window.gtag('config', 'G-SMW88C72JE', {
      page_location: url,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  if (
    window &&
    typeof window !== 'undefined' &&
    !!window.gtag &&
    getCookieConsentValue() === 'true'
  ) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};
